import {
  getSchools,
  deleteInfo,
  updateInfo,
  registerButtonSwitch,
  addSchool,
} from "./schoolApi";
import { ElMessage } from "element-plus";
import { dealDate } from "../../course-manage/trade-manage/tradeMethods";
import { ElMessageBox } from "element-plus";

export const getUserLists = (data) => {
  data.loading = true;
  let parame = {
    schoolName: data.userInput,
    pageSize: data.pageSize,
    pageIndex: data.currentPage,
  };
  getSchools(parame).then((res) => {
    if (res.data.code === "200") {
      let newRes = res.data.data;
      data.spreadTable = newRes.schoolPageInfo.list;
      data.total = newRes.schoolPageInfo.total;
      dealDate(data.spreadTable, ["createTime"]);

      setTimeout(() => {
        data.loading = false;
      }, 300);
    }
  });
};

// 切换条数
export const sizeChanges = (val, data) => {
  data.pageSize = val;
  getUserLists(data);
};

// 切换页数
export const currentChanges = (val, data) => {
  data.currentPage = val;
  getUserLists(data);
};

export const delDrafrFiles = (item, data) => {
  let parame = {
    id: item.id,
  };
  deleteInfo(parame).then((res) => {
    if (res.data.code === "200") {
      ElMessage.success(res.data.msg);
      getUserLists(data);
    } else {
      ElMessage.error(res.data.msg);
    }
  });
};

export const clickButtons = (val, data) => {
  if (val.type === "删除") {
    ElMessageBox.confirm("此操作将永久删除该学校，是否继续删除", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    }).then(() => {
      delDrafrFiles(val.item, data);
    });
  }
  if (val.type === "修改") {
    dealRole(val.item, data);
  }
};

// 修改 信息
export const dealRole = (val, data) => {
  data.showRoleDia = true;
  data.studentName = val.schoolName;
  data.studentUrl = val.schoolDomain;
  data.studentItem = val;
};

// 确认修改
export const saveInfos = (data) => {
  let parame = {
    id: data.studentItem.id,
    schoolName: data.studentName,
    schoolDomain: data.studentUrl,
  };
  updateInfo(parame).then((res) => {
    if (res.data.code === "200") {
      ElMessage.success(res.data.msg);
      data.studentItem = {};
      backInfo(data);
      getUserLists(data);
    } else {
      ElMessage.error(res.data.msg);
    }
  });
};
// 取消修改
export const backInfo = (data) => {
  data.studentUrl = "";
  data.studentName = "";
  data.showRoleDia = false;
};

// 注册开关
export const controls = (data) => {
  let parame = {
    registerButtonSwitch: data.value ? "open" : "close",
  };
  registerButtonSwitch(parame).then((res) => {
    if (res.data.code === "200") {
      let newRes = res.data;
      ElMessage.success(res.data.msg);
    }
  });
};

// 添加学校
export const schoolSaveInfos = (data) => {
    let  parame = {
      id: data.studentItem.id,
      schoolName:data.schoolName,
      schoolDomain:data.schoolUrl
      // createUser:
    };
    addSchool(parame).then((res) => {
      if(res.data.code === '200' ){
          ElMessage.success(res.data.data);
        } else if (res.data.code !== '200'){
          ElMessage.error(res.data.data);
        } 
        data.schoolName = ''
        data.schoolUrl = ''
        data.schoolRoleDia = false
       getUserLists(data);
    })
}

//控制开关 
export const SwitchButtons = (val,data) => {
  let parame = {
    registeredSwitch: val.registeredSwitch,
    id:val.id
  };
  updateInfo(parame).then((res)=>{
    if(res.data.code === '200' ){
          ElMessage.success(res.data.msg);
          getUserLists(data);
        } else{
          ElMessage.error(res.data.msg);
        } 
        
    })
}