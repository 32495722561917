import axios from '@/axios/globalaxios'

// 查询学校 信息
export const getSchools = data =>{
    return axios({
        url: "/tradelab/school/schools",
        method: 'post',
        data:data
    })
}
// 删除 学校信息
export const deleteInfo = data =>{
    return axios({
        url: "/tradelab/school/delete",
        method: 'post',
        data:data
    })
}
// 修改 学校信息
export const updateInfo = data =>{
    return axios({
        url: "/tradelab/school/update",
        method: 'post',
        data:data
    })
}

// 注册开关
export const registerButtonSwitch = data =>{
    return axios({
        url: "/tradelab/sysUser/registerButtonSwitch",
        method: 'post',
        data:data
    })
}

// 添加学校
export const addSchool = data =>{
    return axios({
        url: "/tradelab/school/addSchool",
        method: 'post',
        data:data
    })
}